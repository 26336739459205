import {ModelsResponseReader, ResourceRoutes, SessionStatusUpdater} from "@kaspernj/api-maker"
import {Route, Router} from "react-router-dom"
import Notifications from "components/notifications"
import ReactDOM from "react-dom"
import ScrollToTop from "components/layout/scroll-to-top"
import {Suspense} from "react"

const LayoutNotFoundPage = React.lazy(() => import(/* webpackChunkName: "[request]" */ "components/layout/not-found-page"))
const Welcome = React.lazy(() => import(/* webpackChunkName: "[request]" */ "routes/welcome"))

if (window.currentUserData) {
  const modelsResponseReader = new ModelsResponseReader({response: window.currentUserData})
  const models = modelsResponseReader.models()

  window.currentUser = models[0]
}

window.sessionStatusUpdater = new SessionStatusUpdater()
sessionStatusUpdater.startTimeout()

document.addEventListener("DOMContentLoaded", () => {
  const reactRoot = document.querySelector(".react-root")
  const routes = ResourceRoutes.readRoutes({
    jsRoutes: Routes,
    locales: true,
    requireComponent: ({routeDefinition}) => React.lazy(() => import(/* webpackChunkName: "[request]" */ `routes/${routeDefinition.component}`)),
    routeDefinitions: require("shared/route-definitions.json")
  })

  ReactDOM.render((
    <>
      <Notifications />
      <Router history={AppHistory}>
        <Suspense fallback={<div />}>
          <ScrollToTop>
            {routes}
            <Route component={Welcome} exact path="/" />
            <Route component={LayoutNotFoundPage} exact={true} path="*" />
          </ScrollToTop>
        </Suspense>
      </Router>
    </>
  ), reactRoot)
})
