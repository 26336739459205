import "./style"
import Notification from "./notification"

export default class NotificationsIndex extends BaseComponent {
  constructor(props) {
    super(props)
    this.count = 0
    this.shape = new Shape(this, {
      notifications: []
    })
  }

  render() {
    const {notifications} = this.shape

    return (
      <div className="component-notifications-index">
        <EventListener event="pushNotification" onCalled={this.onPushNotification} target={window} />

        {notifications.map((notification) =>
          <Notification
            className="mb-3"
            key={`notification-${notification.count}`}
            message={notification.message}
            onRemovedClicked={(e) => this.onRemovedClicked(e, notification)}
            title={notification.title}
            type={notification.type}
          />
        )}
      </div>
    )
  }

  onPushNotification = (event) => {
    const count = this.count
    const detail = digg(event, "detail")

    this.count += 1
    setTimeout(() => this.removeNotification(count), 4000)

    const notification = {
      count,
      message: digg(detail, "message"),
      title: digg(detail, "title"),
      type: digg(detail, "type")
    }

    this.shape.set({
      notifications: this.shape.notifications.concat([notification])
    })
  }

  onRemovedClicked(e, notification) {
    e.preventDefault()
    this.removeNotification(digg(notification, "count"))
  }

  removeNotification = (count) => {
    this.shape.set({
      notifications: this.shape.notifications.filter((notification) => notification.count != count)
    })
  }
}
