export default class Locales {
  static availableLocales() {
    return ["da", "de", "en"]
  }

  static countryCodeForLocale(locale) {
    const localeToCountryCode = {
      da: "dk",
      de: "de",
      en: "us"
    }

    return localeToCountryCode[locale]
  }
}
