/* rails-erb-loader-dependencies api_maker/resources/ */

import {BaseModel, Collection} from "@kaspernj/api-maker"
import {digg} from "@kaspernj/object-digger"

class GithubWebhook extends BaseModel {
  static modelClassData() {
    return {"attributes":[{"name":"id"},{"name":"name"},{"name":"project_id"},{"name":"payload_target_url"},{"name":"secret"}],"className":"GithubWebhook","collectionKey":"github_webhooks","collectionName":"github_webhooks","i18nKey":"github_webhook","name":"GithubWebhook","pluralName":"github_webhooks","relationships":[{"className":"Project","collectionName":"projects","name":"project","macro":"belongs_to","resource_name":"Project"}],"paramKey":"github_webhook","path":"/api_maker/github_webhooks","primaryKey":"id"}
  }

  
    
      loadProject() {
        const id = this.projectId()
        const resourceName = "Project"
        const modelClass = digg(require("api-maker/models"), resourceName)
        return this._loadBelongsToReflection({"reflectionName":"project","model":this,"modelClass":modelClass}, {"ransack":{"id_eq":id}})
      }

      project() {
        const resourceName = "Project"
        const modelClass = digg(require("api-maker/models"), resourceName)
        return this._readBelongsToReflection({"reflectionName":"project","model":this,"modelClass":modelClass})
      }
    
  

  
    
    id() {
      return this.readAttributeUnderscore("id")
    }

    hasId() {
      const value = this.id()
      return this._isPresent(value)
    }
  
    
    name() {
      return this.readAttributeUnderscore("name")
    }

    hasName() {
      const value = this.name()
      return this._isPresent(value)
    }
  
    
    projectId() {
      return this.readAttributeUnderscore("project_id")
    }

    hasProjectId() {
      const value = this.projectId()
      return this._isPresent(value)
    }
  
    
    payloadTargetUrl() {
      return this.readAttributeUnderscore("payload_target_url")
    }

    hasPayloadTargetUrl() {
      const value = this.payloadTargetUrl()
      return this._isPresent(value)
    }
  
    
    secret() {
      return this.readAttributeUnderscore("secret")
    }

    hasSecret() {
      const value = this.secret()
      return this._isPresent(value)
    }
  

  

  
}


class SiteRole extends BaseModel {
  static modelClassData() {
    return {"attributes":[{"name":"id"},{"name":"role"}],"className":"SiteRole","collectionKey":"site_roles","collectionName":"site_roles","i18nKey":"site_role","name":"SiteRole","pluralName":"site_roles","relationships":[],"paramKey":"site_role","path":"/api_maker/site_roles","primaryKey":"id"}
  }

  

  
    
    id() {
      return this.readAttributeUnderscore("id")
    }

    hasId() {
      const value = this.id()
      return this._isPresent(value)
    }
  
    
    role() {
      return this.readAttributeUnderscore("role")
    }

    hasRole() {
      const value = this.role()
      return this._isPresent(value)
    }
  

  

  
}


class AccountInvitation extends BaseModel {
  static modelClassData() {
    return {"attributes":[{"name":"created_at"},{"name":"email"},{"name":"id"},{"name":"translated_state"}],"className":"AccountInvitation","collectionKey":"account_invitations","collectionName":"account_invitations","i18nKey":"account_invitation","name":"AccountInvitation","pluralName":"account_invitations","relationships":[],"paramKey":"account_invitation","path":"/api_maker/account_invitations","primaryKey":"id"}
  }

  

  
    
    createdAt() {
      return this.readAttributeUnderscore("created_at")
    }

    hasCreatedAt() {
      const value = this.createdAt()
      return this._isPresent(value)
    }
  
    
    email() {
      return this.readAttributeUnderscore("email")
    }

    hasEmail() {
      const value = this.email()
      return this._isPresent(value)
    }
  
    
    id() {
      return this.readAttributeUnderscore("id")
    }

    hasId() {
      const value = this.id()
      return this._isPresent(value)
    }
  
    
    translatedState() {
      return this.readAttributeUnderscore("translated_state")
    }

    hasTranslatedState() {
      const value = this.translatedState()
      return this._isPresent(value)
    }
  

  
    static getByToken(args, commandArgs = {}) {
      return this._callCollectionCommand(
        {
          args: args,
          command: "get_by_token",
          collectionName: this.modelClassData().collectionName,
          type: "collection"
        },
        commandArgs
      )
    }
  

  
    acceptInvite(args, commandArgs = {}) {
      return this._callMemberCommand(
        {
          args: args,
          command: "accept_invite",
          primaryKey: this.primaryKey(),
          collectionName: this.modelClassData().collectionName,
          type: "member"
        },
        commandArgs
      )
    }
  
    cancel(args, commandArgs = {}) {
      return this._callMemberCommand(
        {
          args: args,
          command: "cancel",
          primaryKey: this.primaryKey(),
          collectionName: this.modelClassData().collectionName,
          type: "member"
        },
        commandArgs
      )
    }
  
    sendInvitation(args, commandArgs = {}) {
      return this._callMemberCommand(
        {
          args: args,
          command: "send_invitation",
          primaryKey: this.primaryKey(),
          collectionName: this.modelClassData().collectionName,
          type: "member"
        },
        commandArgs
      )
    }
  
}


class GithubWebhookDelivery extends BaseModel {
  static modelClassData() {
    return {"attributes":[{"name":"created_at"},{"name":"delivery"},{"name":"event_type"},{"name":"id"},{"name":"payload"},{"name":"updated_at"}],"className":"GithubWebhookDelivery","collectionKey":"github_webhook_deliveries","collectionName":"github_webhook_deliveries","i18nKey":"github_webhook_delivery","name":"GithubWebhookDelivery","pluralName":"github_webhook_deliveries","relationships":[],"paramKey":"github_webhook_delivery","path":"/api_maker/github_webhook_deliveries","primaryKey":"id"}
  }

  

  
    
    createdAt() {
      return this.readAttributeUnderscore("created_at")
    }

    hasCreatedAt() {
      const value = this.createdAt()
      return this._isPresent(value)
    }
  
    
    delivery() {
      return this.readAttributeUnderscore("delivery")
    }

    hasDelivery() {
      const value = this.delivery()
      return this._isPresent(value)
    }
  
    
    eventType() {
      return this.readAttributeUnderscore("event_type")
    }

    hasEventType() {
      const value = this.eventType()
      return this._isPresent(value)
    }
  
    
    id() {
      return this.readAttributeUnderscore("id")
    }

    hasId() {
      const value = this.id()
      return this._isPresent(value)
    }
  
    
    payload() {
      return this.readAttributeUnderscore("payload")
    }

    hasPayload() {
      const value = this.payload()
      return this._isPresent(value)
    }
  
    
    updatedAt() {
      return this.readAttributeUnderscore("updated_at")
    }

    hasUpdatedAt() {
      const value = this.updatedAt()
      return this._isPresent(value)
    }
  

  

  
}


class Workplace extends BaseModel {
  static modelClassData() {
    return {"attributes":[{"name":"id"}],"className":"WorkerPlugins::Workplace","collectionKey":"worker_plugins/workplaces","collectionName":"workplaces","i18nKey":"worker_plugins/workplace","name":"Workplace","pluralName":"worker_plugins_workplaces","relationships":[{"className":"WorkplaceLink","collectionName":"workplace_links","name":"workplace_links","macro":"has_many","resource_name":"WorkplaceLink"}],"paramKey":"workplace","path":"/api_maker/workplaces","primaryKey":"id"}
  }

  
    
      
        loadWorkplaceLinks() {
          const id = this.id()
          const resourceName = "WorkplaceLink"
          const modelClass = digg(require("api-maker/models"), resourceName)
          return this._loadHasManyReflection({"reflectionName":"workplace_links","model":this,"modelClass":modelClass}, {"ransack":{"workplace_id_eq":id}})
        }
      

      workplaceLinks() {
        const id = this.id()
        const resourceName = "WorkplaceLink"
        const modelClass = digg(require("api-maker/models"), resourceName)
        return new Collection({"reflectionName":"workplace_links","model":this,"modelName":"WorkplaceLink","modelClass":modelClass}, {"ransack":{"workplace_id_eq":id}})
      }
    
  

  
    
    id() {
      return this.readAttributeUnderscore("id")
    }

    hasId() {
      const value = this.id()
      return this._isPresent(value)
    }
  

  
    static destroyLinks(args, commandArgs = {}) {
      return this._callCollectionCommand(
        {
          args: args,
          command: "destroy_links",
          collectionName: this.modelClassData().collectionName,
          type: "collection"
        },
        commandArgs
      )
    }
  
    static createLink(args, commandArgs = {}) {
      return this._callCollectionCommand(
        {
          args: args,
          command: "create_link",
          collectionName: this.modelClassData().collectionName,
          type: "collection"
        },
        commandArgs
      )
    }
  
    static current(args, commandArgs = {}) {
      return this._callCollectionCommand(
        {
          args: args,
          command: "current",
          collectionName: this.modelClassData().collectionName,
          type: "collection"
        },
        commandArgs
      )
    }
  
    static linkFor(args, commandArgs = {}) {
      return this._callCollectionCommand(
        {
          args: args,
          command: "link_for",
          collectionName: this.modelClassData().collectionName,
          type: "collection"
        },
        commandArgs
      )
    }
  
    static switchQueryOnWorkplace(args, commandArgs = {}) {
      return this._callCollectionCommand(
        {
          args: args,
          command: "switch_query_on_workplace",
          collectionName: this.modelClassData().collectionName,
          type: "collection"
        },
        commandArgs
      )
    }
  

  
    deleteAllLinks(args, commandArgs = {}) {
      return this._callMemberCommand(
        {
          args: args,
          command: "delete_all_links",
          primaryKey: this.primaryKey(),
          collectionName: this.modelClassData().collectionName,
          type: "member"
        },
        commandArgs
      )
    }
  
}


class AccountUser extends BaseModel {
  static modelClassData() {
    return {"attributes":[{"name":"account_id"},{"name":"id"},{"name":"user_id"}],"className":"AccountUser","collectionKey":"account_users","collectionName":"account_users","i18nKey":"account_user","name":"AccountUser","pluralName":"account_users","relationships":[{"className":"Account","collectionName":"accounts","name":"account","macro":"belongs_to","resource_name":"Account"},{"className":"User","collectionName":"users","name":"user","macro":"belongs_to","resource_name":"User"}],"paramKey":"account_user","path":"/api_maker/account_users","primaryKey":"id"}
  }

  
    
      loadAccount() {
        const id = this.accountId()
        const resourceName = "Account"
        const modelClass = digg(require("api-maker/models"), resourceName)
        return this._loadBelongsToReflection({"reflectionName":"account","model":this,"modelClass":modelClass}, {"ransack":{"id_eq":id}})
      }

      account() {
        const resourceName = "Account"
        const modelClass = digg(require("api-maker/models"), resourceName)
        return this._readBelongsToReflection({"reflectionName":"account","model":this,"modelClass":modelClass})
      }
    
  
    
      loadUser() {
        const id = this.userId()
        const resourceName = "User"
        const modelClass = digg(require("api-maker/models"), resourceName)
        return this._loadBelongsToReflection({"reflectionName":"user","model":this,"modelClass":modelClass}, {"ransack":{"id_eq":id}})
      }

      user() {
        const resourceName = "User"
        const modelClass = digg(require("api-maker/models"), resourceName)
        return this._readBelongsToReflection({"reflectionName":"user","model":this,"modelClass":modelClass})
      }
    
  

  
    
    accountId() {
      return this.readAttributeUnderscore("account_id")
    }

    hasAccountId() {
      const value = this.accountId()
      return this._isPresent(value)
    }
  
    
    id() {
      return this.readAttributeUnderscore("id")
    }

    hasId() {
      const value = this.id()
      return this._isPresent(value)
    }
  
    
    userId() {
      return this.readAttributeUnderscore("user_id")
    }

    hasUserId() {
      const value = this.userId()
      return this._isPresent(value)
    }
  

  

  
}


class Repository extends BaseModel {
  static modelClassData() {
    return {"attributes":[{"name":"id"}],"className":"Repository","collectionKey":"repositories","collectionName":"repositories","i18nKey":"repository","name":"Repository","pluralName":"repositories","relationships":[],"paramKey":"repository","path":"/api_maker/repositories","primaryKey":"id"}
  }

  

  
    
    id() {
      return this.readAttributeUnderscore("id")
    }

    hasId() {
      const value = this.id()
      return this._isPresent(value)
    }
  

  

  
}


class ProfileThirdPartyAccount extends BaseModel {
  static modelClassData() {
    return {"attributes":[{"name":"id"},{"name":"profile_id"},{"name":"third_party_account_id"}],"className":"ProfileThirdPartyAccount","collectionKey":"profile_third_party_accounts","collectionName":"profile_third_party_accounts","i18nKey":"profile_third_party_account","name":"ProfileThirdPartyAccount","pluralName":"profile_third_party_accounts","relationships":[],"paramKey":"profile_third_party_account","path":"/api_maker/profile_third_party_accounts","primaryKey":"id"}
  }

  

  
    
    id() {
      return this.readAttributeUnderscore("id")
    }

    hasId() {
      const value = this.id()
      return this._isPresent(value)
    }
  
    
    profileId() {
      return this.readAttributeUnderscore("profile_id")
    }

    hasProfileId() {
      const value = this.profileId()
      return this._isPresent(value)
    }
  
    
    thirdPartyAccountId() {
      return this.readAttributeUnderscore("third_party_account_id")
    }

    hasThirdPartyAccountId() {
      const value = this.thirdPartyAccountId()
      return this._isPresent(value)
    }
  

  

  
}


class ThirdPartyAccount extends BaseModel {
  static modelClassData() {
    return {"attributes":[{"name":"account_type"},{"name":"created_at"},{"name":"email"},{"name":"github_identifier"},{"name":"id"},{"name":"name"},{"name":"username"},{"name":"updated_at"}],"className":"ThirdPartyAccount","collectionKey":"third_party_accounts","collectionName":"third_party_accounts","i18nKey":"third_party_account","name":"ThirdPartyAccount","pluralName":"third_party_accounts","relationships":[],"paramKey":"third_party_account","path":"/api_maker/third_party_accounts","primaryKey":"id"}
  }

  

  
    
    accountType() {
      return this.readAttributeUnderscore("account_type")
    }

    hasAccountType() {
      const value = this.accountType()
      return this._isPresent(value)
    }
  
    
    createdAt() {
      return this.readAttributeUnderscore("created_at")
    }

    hasCreatedAt() {
      const value = this.createdAt()
      return this._isPresent(value)
    }
  
    
    email() {
      return this.readAttributeUnderscore("email")
    }

    hasEmail() {
      const value = this.email()
      return this._isPresent(value)
    }
  
    
    githubIdentifier() {
      return this.readAttributeUnderscore("github_identifier")
    }

    hasGithubIdentifier() {
      const value = this.githubIdentifier()
      return this._isPresent(value)
    }
  
    
    id() {
      return this.readAttributeUnderscore("id")
    }

    hasId() {
      const value = this.id()
      return this._isPresent(value)
    }
  
    
    name() {
      return this.readAttributeUnderscore("name")
    }

    hasName() {
      const value = this.name()
      return this._isPresent(value)
    }
  
    
    username() {
      return this.readAttributeUnderscore("username")
    }

    hasUsername() {
      const value = this.username()
      return this._isPresent(value)
    }
  
    
    updatedAt() {
      return this.readAttributeUnderscore("updated_at")
    }

    hasUpdatedAt() {
      const value = this.updatedAt()
      return this._isPresent(value)
    }
  

  
    static merge(args, commandArgs = {}) {
      return this._callCollectionCommand(
        {
          args: args,
          command: "merge",
          collectionName: this.modelClassData().collectionName,
          type: "collection"
        },
        commandArgs
      )
    }
  

  
}


class JiraWebhook extends BaseModel {
  static modelClassData() {
    return {"attributes":[{"name":"id"},{"name":"jira_integration_id"}],"className":"JiraWebhook","collectionKey":"jira_webhooks","collectionName":"jira_webhooks","i18nKey":"jira_webhook","name":"JiraWebhook","pluralName":"jira_webhooks","relationships":[{"className":"JiraIntegration","collectionName":"jira_integrations","name":"jira_integration","macro":"belongs_to","resource_name":"JiraIntegration"},{"className":"JiraWebhookDelivery","collectionName":"jira_webhook_deliveries","name":"jira_webhook_deliveries","macro":"has_many","resource_name":"JiraWebhookDelivery"}],"paramKey":"jira_webhook","path":"/api_maker/jira_webhooks","primaryKey":"id"}
  }

  
    
      loadJiraIntegration() {
        const id = this.jiraIntegrationId()
        const resourceName = "JiraIntegration"
        const modelClass = digg(require("api-maker/models"), resourceName)
        return this._loadBelongsToReflection({"reflectionName":"jira_integration","model":this,"modelClass":modelClass}, {"ransack":{"id_eq":id}})
      }

      jiraIntegration() {
        const resourceName = "JiraIntegration"
        const modelClass = digg(require("api-maker/models"), resourceName)
        return this._readBelongsToReflection({"reflectionName":"jira_integration","model":this,"modelClass":modelClass})
      }
    
  
    
      
        loadJiraWebhookDeliveries() {
          const id = this.id()
          const resourceName = "JiraWebhookDelivery"
          const modelClass = digg(require("api-maker/models"), resourceName)
          return this._loadHasManyReflection({"reflectionName":"jira_webhook_deliveries","model":this,"modelClass":modelClass}, {"ransack":{"jira_webhook_id_eq":id}})
        }
      

      jiraWebhookDeliveries() {
        const id = this.id()
        const resourceName = "JiraWebhookDelivery"
        const modelClass = digg(require("api-maker/models"), resourceName)
        return new Collection({"reflectionName":"jira_webhook_deliveries","model":this,"modelName":"JiraWebhookDelivery","modelClass":modelClass}, {"ransack":{"jira_webhook_id_eq":id}})
      }
    
  

  
    
    id() {
      return this.readAttributeUnderscore("id")
    }

    hasId() {
      const value = this.id()
      return this._isPresent(value)
    }
  
    
    jiraIntegrationId() {
      return this.readAttributeUnderscore("jira_integration_id")
    }

    hasJiraIntegrationId() {
      const value = this.jiraIntegrationId()
      return this._isPresent(value)
    }
  

  

  
}


class RepositoryCommit extends BaseModel {
  static modelClassData() {
    return {"attributes":[{"name":"committed_at"},{"name":"id"},{"name":"identifier"},{"name":"message"}],"className":"RepositoryCommit","collectionKey":"repository_commits","collectionName":"repository_commits","i18nKey":"repository_commit","name":"RepositoryCommit","pluralName":"repository_commits","relationships":[{"className":"RepositoryCommitAuthor","collectionName":"repository_commit_authors","name":"repository_commit_authors","macro":"has_many","resource_name":"RepositoryCommitAuthor"},{"className":"Profile","collectionName":"profiles","name":"profiles","macro":"has_many","resource_name":"Profile"}],"paramKey":"repository_commit","path":"/api_maker/repository_commits","primaryKey":"id"}
  }

  
    
      
        loadRepositoryCommitAuthors() {
          const id = this.id()
          const resourceName = "RepositoryCommitAuthor"
          const modelClass = digg(require("api-maker/models"), resourceName)
          return this._loadHasManyReflection({"reflectionName":"repository_commit_authors","model":this,"modelClass":modelClass}, {"ransack":{"repository_commit_id_eq":id}})
        }
      

      repositoryCommitAuthors() {
        const id = this.id()
        const resourceName = "RepositoryCommitAuthor"
        const modelClass = digg(require("api-maker/models"), resourceName)
        return new Collection({"reflectionName":"repository_commit_authors","model":this,"modelName":"RepositoryCommitAuthor","modelClass":modelClass}, {"ransack":{"repository_commit_id_eq":id}})
      }
    
  
    
      
        loadProfiles() {
          const id = this.id()
          const resourceName = "Profile"
          const modelClass = digg(require("api-maker/models"), resourceName)
          return this._loadHasManyReflection({"reflectionName":"profiles","model":this,"modelClass":modelClass}, {"params":{"through":{"model":"RepositoryCommit","id":id,"reflection":"profiles"}}})
        }
      

      profiles() {
        const id = this.id()
        const resourceName = "Profile"
        const modelClass = digg(require("api-maker/models"), resourceName)
        return new Collection({"reflectionName":"profiles","model":this,"modelName":"Profile","modelClass":modelClass}, {"params":{"through":{"model":"RepositoryCommit","id":id,"reflection":"profiles"}}})
      }
    
  

  
    
    committedAt() {
      return this.readAttributeUnderscore("committed_at")
    }

    hasCommittedAt() {
      const value = this.committedAt()
      return this._isPresent(value)
    }
  
    
    id() {
      return this.readAttributeUnderscore("id")
    }

    hasId() {
      const value = this.id()
      return this._isPresent(value)
    }
  
    
    identifier() {
      return this.readAttributeUnderscore("identifier")
    }

    hasIdentifier() {
      const value = this.identifier()
      return this._isPresent(value)
    }
  
    
    message() {
      return this.readAttributeUnderscore("message")
    }

    hasMessage() {
      const value = this.message()
      return this._isPresent(value)
    }
  

  

  
}


class Profile extends BaseModel {
  static modelClassData() {
    return {"attributes":[{"name":"created_at"},{"name":"id"},{"name":"name"},{"name":"updated_at"}],"className":"Profile","collectionKey":"profiles","collectionName":"profiles","i18nKey":"profile","name":"Profile","pluralName":"profiles","relationships":[{"className":"Account","collectionName":"accounts","name":"account","macro":"belongs_to","resource_name":"Account"},{"className":"ThirdPartyAccount","collectionName":"third_party_accounts","name":"third_party_accounts","macro":"has_many","resource_name":"ThirdPartyAccount"}],"paramKey":"profile","path":"/api_maker/profiles","primaryKey":"id"}
  }

  
    
      loadAccount() {
        const id = this.accountId()
        const resourceName = "Account"
        const modelClass = digg(require("api-maker/models"), resourceName)
        return this._loadBelongsToReflection({"reflectionName":"account","model":this,"modelClass":modelClass}, {"ransack":{"id_eq":id}})
      }

      account() {
        const resourceName = "Account"
        const modelClass = digg(require("api-maker/models"), resourceName)
        return this._readBelongsToReflection({"reflectionName":"account","model":this,"modelClass":modelClass})
      }
    
  
    
      
        loadThirdPartyAccounts() {
          const id = this.id()
          const resourceName = "ThirdPartyAccount"
          const modelClass = digg(require("api-maker/models"), resourceName)
          return this._loadHasManyReflection({"reflectionName":"third_party_accounts","model":this,"modelClass":modelClass}, {"params":{"through":{"model":"Profile","id":id,"reflection":"third_party_accounts"}}})
        }
      

      thirdPartyAccounts() {
        const id = this.id()
        const resourceName = "ThirdPartyAccount"
        const modelClass = digg(require("api-maker/models"), resourceName)
        return new Collection({"reflectionName":"third_party_accounts","model":this,"modelName":"ThirdPartyAccount","modelClass":modelClass}, {"params":{"through":{"model":"Profile","id":id,"reflection":"third_party_accounts"}}})
      }
    
  

  
    
    createdAt() {
      return this.readAttributeUnderscore("created_at")
    }

    hasCreatedAt() {
      const value = this.createdAt()
      return this._isPresent(value)
    }
  
    
    id() {
      return this.readAttributeUnderscore("id")
    }

    hasId() {
      const value = this.id()
      return this._isPresent(value)
    }
  
    
    name() {
      return this.readAttributeUnderscore("name")
    }

    hasName() {
      const value = this.name()
      return this._isPresent(value)
    }
  
    
    updatedAt() {
      return this.readAttributeUnderscore("updated_at")
    }

    hasUpdatedAt() {
      const value = this.updatedAt()
      return this._isPresent(value)
    }
  

  

  
}


class Timelog extends BaseModel {
  static modelClassData() {
    return {"attributes":[{"name":"comment"},{"name":"created_at"},{"name":"deleted_at"},{"name":"id"},{"name":"identifier"},{"name":"logged_at"},{"name":"time"}],"className":"Timelog","collectionKey":"timelogs","collectionName":"timelogs","i18nKey":"timelog","name":"Timelog","pluralName":"timelogs","relationships":[{"className":"JiraIntegration","collectionName":"jira_integrations","name":"jira_integration","macro":"belongs_to","resource_name":"JiraIntegration"},{"className":"Project","collectionName":"projects","name":"project","macro":"belongs_to","resource_name":"Project"},{"className":"Task","collectionName":"tasks","name":"tasks","macro":"has_many","resource_name":"Task"},{"className":"ThirdPartyAccount","collectionName":"third_party_accounts","name":"third_party_account","macro":"belongs_to","resource_name":"ThirdPartyAccount"}],"paramKey":"timelog","path":"/api_maker/timelogs","primaryKey":"id"}
  }

  
    
      loadJiraIntegration() {
        const id = this.jiraIntegrationId()
        const resourceName = "JiraIntegration"
        const modelClass = digg(require("api-maker/models"), resourceName)
        return this._loadBelongsToReflection({"reflectionName":"jira_integration","model":this,"modelClass":modelClass}, {"ransack":{"id_eq":id}})
      }

      jiraIntegration() {
        const resourceName = "JiraIntegration"
        const modelClass = digg(require("api-maker/models"), resourceName)
        return this._readBelongsToReflection({"reflectionName":"jira_integration","model":this,"modelClass":modelClass})
      }
    
  
    
      loadProject() {
        const id = this.projectId()
        const resourceName = "Project"
        const modelClass = digg(require("api-maker/models"), resourceName)
        return this._loadBelongsToReflection({"reflectionName":"project","model":this,"modelClass":modelClass}, {"ransack":{"id_eq":id}})
      }

      project() {
        const resourceName = "Project"
        const modelClass = digg(require("api-maker/models"), resourceName)
        return this._readBelongsToReflection({"reflectionName":"project","model":this,"modelClass":modelClass})
      }
    
  
    
      
        loadTasks() {
          const id = this.id()
          const resourceName = "Task"
          const modelClass = digg(require("api-maker/models"), resourceName)
          return this._loadHasManyReflection({"reflectionName":"tasks","model":this,"modelClass":modelClass}, {"params":{"through":{"model":"Timelog","id":id,"reflection":"tasks"}}})
        }
      

      tasks() {
        const id = this.id()
        const resourceName = "Task"
        const modelClass = digg(require("api-maker/models"), resourceName)
        return new Collection({"reflectionName":"tasks","model":this,"modelName":"Task","modelClass":modelClass}, {"params":{"through":{"model":"Timelog","id":id,"reflection":"tasks"}}})
      }
    
  
    
      loadThirdPartyAccount() {
        const id = this.thirdPartyAccountId()
        const resourceName = "ThirdPartyAccount"
        const modelClass = digg(require("api-maker/models"), resourceName)
        return this._loadBelongsToReflection({"reflectionName":"third_party_account","model":this,"modelClass":modelClass}, {"ransack":{"id_eq":id}})
      }

      thirdPartyAccount() {
        const resourceName = "ThirdPartyAccount"
        const modelClass = digg(require("api-maker/models"), resourceName)
        return this._readBelongsToReflection({"reflectionName":"third_party_account","model":this,"modelClass":modelClass})
      }
    
  

  
    
    comment() {
      return this.readAttributeUnderscore("comment")
    }

    hasComment() {
      const value = this.comment()
      return this._isPresent(value)
    }
  
    
    createdAt() {
      return this.readAttributeUnderscore("created_at")
    }

    hasCreatedAt() {
      const value = this.createdAt()
      return this._isPresent(value)
    }
  
    
    deletedAt() {
      return this.readAttributeUnderscore("deleted_at")
    }

    hasDeletedAt() {
      const value = this.deletedAt()
      return this._isPresent(value)
    }
  
    
    id() {
      return this.readAttributeUnderscore("id")
    }

    hasId() {
      const value = this.id()
      return this._isPresent(value)
    }
  
    
    identifier() {
      return this.readAttributeUnderscore("identifier")
    }

    hasIdentifier() {
      const value = this.identifier()
      return this._isPresent(value)
    }
  
    
    loggedAt() {
      return this.readAttributeUnderscore("logged_at")
    }

    hasLoggedAt() {
      const value = this.loggedAt()
      return this._isPresent(value)
    }
  
    
    time() {
      return this.readAttributeUnderscore("time")
    }

    hasTime() {
      const value = this.time()
      return this._isPresent(value)
    }
  

  

  
}


class User extends BaseModel {
  static modelClassData() {
    return {"attributes":[{"name":"email"},{"name":"id"},{"name":"name"}],"className":"User","collectionKey":"users","collectionName":"users","i18nKey":"user","name":"User","pluralName":"users","relationships":[{"className":"SiteRole","collectionName":"site_roles","name":"site_roles","macro":"has_many","resource_name":"SiteRole"}],"paramKey":"user","path":"/api_maker/users","primaryKey":"id"}
  }

  
    
      
        loadSiteRoles() {
          const id = this.id()
          const resourceName = "SiteRole"
          const modelClass = digg(require("api-maker/models"), resourceName)
          return this._loadHasManyReflection({"reflectionName":"site_roles","model":this,"modelClass":modelClass}, {"params":{"through":{"model":"User","id":id,"reflection":"site_roles"}}})
        }
      

      siteRoles() {
        const id = this.id()
        const resourceName = "SiteRole"
        const modelClass = digg(require("api-maker/models"), resourceName)
        return new Collection({"reflectionName":"site_roles","model":this,"modelName":"SiteRole","modelClass":modelClass}, {"params":{"through":{"model":"User","id":id,"reflection":"site_roles"}}})
      }
    
  

  
    
    email() {
      return this.readAttributeUnderscore("email")
    }

    hasEmail() {
      const value = this.email()
      return this._isPresent(value)
    }
  
    
    id() {
      return this.readAttributeUnderscore("id")
    }

    hasId() {
      const value = this.id()
      return this._isPresent(value)
    }
  
    
    name() {
      return this.readAttributeUnderscore("name")
    }

    hasName() {
      const value = this.name()
      return this._isPresent(value)
    }
  

  
    static changeLocale(args, commandArgs = {}) {
      return this._callCollectionCommand(
        {
          args: args,
          command: "change_locale",
          collectionName: this.modelClassData().collectionName,
          type: "collection"
        },
        commandArgs
      )
    }
  
    static confirmEmail(args, commandArgs = {}) {
      return this._callCollectionCommand(
        {
          args: args,
          command: "confirm_email",
          collectionName: this.modelClassData().collectionName,
          type: "collection"
        },
        commandArgs
      )
    }
  
    static forgotPassword(args, commandArgs = {}) {
      return this._callCollectionCommand(
        {
          args: args,
          command: "forgot_password",
          collectionName: this.modelClassData().collectionName,
          type: "collection"
        },
        commandArgs
      )
    }
  
    static resendConfirmation(args, commandArgs = {}) {
      return this._callCollectionCommand(
        {
          args: args,
          command: "resend_confirmation",
          collectionName: this.modelClassData().collectionName,
          type: "collection"
        },
        commandArgs
      )
    }
  

  
}


class RepositoryPullRequest extends BaseModel {
  static modelClassData() {
    return {"attributes":[{"name":"closed_at"},{"name":"created_at"},{"name":"identifier"},{"name":"merged_at"},{"name":"id"},{"name":"pull_request_created_at"},{"name":"title"},{"name":"updated_at"}],"className":"RepositoryPullRequest","collectionKey":"repository_pull_requests","collectionName":"repository_pull_requests","i18nKey":"repository_pull_request","name":"RepositoryPullRequest","pluralName":"repository_pull_requests","relationships":[{"className":"ThirdPartyAccount","collectionName":"third_party_accounts","name":"third_party_account","macro":"belongs_to","resource_name":"ThirdPartyAccount"}],"paramKey":"repository_pull_request","path":"/api_maker/repository_pull_requests","primaryKey":"id"}
  }

  
    
      loadThirdPartyAccount() {
        const id = this.thirdPartyAccountId()
        const resourceName = "ThirdPartyAccount"
        const modelClass = digg(require("api-maker/models"), resourceName)
        return this._loadBelongsToReflection({"reflectionName":"third_party_account","model":this,"modelClass":modelClass}, {"ransack":{"id_eq":id}})
      }

      thirdPartyAccount() {
        const resourceName = "ThirdPartyAccount"
        const modelClass = digg(require("api-maker/models"), resourceName)
        return this._readBelongsToReflection({"reflectionName":"third_party_account","model":this,"modelClass":modelClass})
      }
    
  

  
    
    closedAt() {
      return this.readAttributeUnderscore("closed_at")
    }

    hasClosedAt() {
      const value = this.closedAt()
      return this._isPresent(value)
    }
  
    
    createdAt() {
      return this.readAttributeUnderscore("created_at")
    }

    hasCreatedAt() {
      const value = this.createdAt()
      return this._isPresent(value)
    }
  
    
    identifier() {
      return this.readAttributeUnderscore("identifier")
    }

    hasIdentifier() {
      const value = this.identifier()
      return this._isPresent(value)
    }
  
    
    mergedAt() {
      return this.readAttributeUnderscore("merged_at")
    }

    hasMergedAt() {
      const value = this.mergedAt()
      return this._isPresent(value)
    }
  
    
    id() {
      return this.readAttributeUnderscore("id")
    }

    hasId() {
      const value = this.id()
      return this._isPresent(value)
    }
  
    
    pullRequestCreatedAt() {
      return this.readAttributeUnderscore("pull_request_created_at")
    }

    hasPullRequestCreatedAt() {
      const value = this.pullRequestCreatedAt()
      return this._isPresent(value)
    }
  
    
    title() {
      return this.readAttributeUnderscore("title")
    }

    hasTitle() {
      const value = this.title()
      return this._isPresent(value)
    }
  
    
    updatedAt() {
      return this.readAttributeUnderscore("updated_at")
    }

    hasUpdatedAt() {
      const value = this.updatedAt()
      return this._isPresent(value)
    }
  

  

  
}


class Task extends BaseModel {
  static modelClassData() {
    return {"attributes":[{"name":"created_at"},{"name":"id"},{"name":"in_backlog_at"},{"name":"in_done_at"},{"name":"in_progress_at"},{"name":"in_review_at"},{"name":"name"},{"name":"state"},{"name":"updated_at"}],"className":"Task","collectionKey":"tasks","collectionName":"tasks","i18nKey":"task","name":"Task","pluralName":"tasks","relationships":[{"className":"ThirdPartyAccount","collectionName":"third_party_accounts","name":"assigned_accounts","macro":"has_many","resource_name":"ThirdPartyAccount"},{"className":"RepositoryPullRequest","collectionName":"repository_pull_requests","name":"repository_pull_requests","macro":"has_many","resource_name":"RepositoryPullRequest"}],"paramKey":"task","path":"/api_maker/tasks","primaryKey":"id"}
  }

  
    
      
        loadAssignedAccounts() {
          const id = this.id()
          const resourceName = "ThirdPartyAccount"
          const modelClass = digg(require("api-maker/models"), resourceName)
          return this._loadHasManyReflection({"reflectionName":"assigned_accounts","model":this,"modelClass":modelClass}, {"params":{"through":{"model":"Task","id":id,"reflection":"assigned_accounts"}}})
        }
      

      assignedAccounts() {
        const id = this.id()
        const resourceName = "ThirdPartyAccount"
        const modelClass = digg(require("api-maker/models"), resourceName)
        return new Collection({"reflectionName":"assigned_accounts","model":this,"modelName":"ThirdPartyAccount","modelClass":modelClass}, {"params":{"through":{"model":"Task","id":id,"reflection":"assigned_accounts"}}})
      }
    
  
    
      
        loadRepositoryPullRequests() {
          const id = this.id()
          const resourceName = "RepositoryPullRequest"
          const modelClass = digg(require("api-maker/models"), resourceName)
          return this._loadHasManyReflection({"reflectionName":"repository_pull_requests","model":this,"modelClass":modelClass}, {"params":{"through":{"model":"Task","id":id,"reflection":"repository_pull_requests"}}})
        }
      

      repositoryPullRequests() {
        const id = this.id()
        const resourceName = "RepositoryPullRequest"
        const modelClass = digg(require("api-maker/models"), resourceName)
        return new Collection({"reflectionName":"repository_pull_requests","model":this,"modelName":"RepositoryPullRequest","modelClass":modelClass}, {"params":{"through":{"model":"Task","id":id,"reflection":"repository_pull_requests"}}})
      }
    
  

  
    
    createdAt() {
      return this.readAttributeUnderscore("created_at")
    }

    hasCreatedAt() {
      const value = this.createdAt()
      return this._isPresent(value)
    }
  
    
    id() {
      return this.readAttributeUnderscore("id")
    }

    hasId() {
      const value = this.id()
      return this._isPresent(value)
    }
  
    
    inBacklogAt() {
      return this.readAttributeUnderscore("in_backlog_at")
    }

    hasInBacklogAt() {
      const value = this.inBacklogAt()
      return this._isPresent(value)
    }
  
    
    inDoneAt() {
      return this.readAttributeUnderscore("in_done_at")
    }

    hasInDoneAt() {
      const value = this.inDoneAt()
      return this._isPresent(value)
    }
  
    
    inProgressAt() {
      return this.readAttributeUnderscore("in_progress_at")
    }

    hasInProgressAt() {
      const value = this.inProgressAt()
      return this._isPresent(value)
    }
  
    
    inReviewAt() {
      return this.readAttributeUnderscore("in_review_at")
    }

    hasInReviewAt() {
      const value = this.inReviewAt()
      return this._isPresent(value)
    }
  
    
    name() {
      return this.readAttributeUnderscore("name")
    }

    hasName() {
      const value = this.name()
      return this._isPresent(value)
    }
  
    
    state() {
      return this.readAttributeUnderscore("state")
    }

    hasState() {
      const value = this.state()
      return this._isPresent(value)
    }
  
    
    updatedAt() {
      return this.readAttributeUnderscore("updated_at")
    }

    hasUpdatedAt() {
      const value = this.updatedAt()
      return this._isPresent(value)
    }
  

  

  
}


class RepositoryCommitAuthor extends BaseModel {
  static modelClassData() {
    return {"attributes":[{"name":"id"}],"className":"RepositoryCommitAuthor","collectionKey":"repository_commit_authors","collectionName":"repository_commit_authors","i18nKey":"repository_commit_author","name":"RepositoryCommitAuthor","pluralName":"repository_commit_authors","relationships":[{"className":"ThirdPartyAccount","collectionName":"third_party_accounts","name":"third_party_account","macro":"belongs_to","resource_name":"ThirdPartyAccount"}],"paramKey":"repository_commit_author","path":"/api_maker/repository_commit_authors","primaryKey":"id"}
  }

  
    
      loadThirdPartyAccount() {
        const id = this.thirdPartyAccountId()
        const resourceName = "ThirdPartyAccount"
        const modelClass = digg(require("api-maker/models"), resourceName)
        return this._loadBelongsToReflection({"reflectionName":"third_party_account","model":this,"modelClass":modelClass}, {"ransack":{"id_eq":id}})
      }

      thirdPartyAccount() {
        const resourceName = "ThirdPartyAccount"
        const modelClass = digg(require("api-maker/models"), resourceName)
        return this._readBelongsToReflection({"reflectionName":"third_party_account","model":this,"modelClass":modelClass})
      }
    
  

  
    
    id() {
      return this.readAttributeUnderscore("id")
    }

    hasId() {
      const value = this.id()
      return this._isPresent(value)
    }
  

  

  
}


class JiraWebhookDelivery extends BaseModel {
  static modelClassData() {
    return {"attributes":[{"name":"created_at"},{"name":"event_type"},{"name":"id"},{"name":"payload"}],"className":"JiraWebhookDelivery","collectionKey":"jira_webhook_deliveries","collectionName":"jira_webhook_deliveries","i18nKey":"jira_webhook_delivery","name":"JiraWebhookDelivery","pluralName":"jira_webhook_deliveries","relationships":[],"paramKey":"jira_webhook_delivery","path":"/api_maker/jira_webhook_deliveries","primaryKey":"id"}
  }

  

  
    
    createdAt() {
      return this.readAttributeUnderscore("created_at")
    }

    hasCreatedAt() {
      const value = this.createdAt()
      return this._isPresent(value)
    }
  
    
    eventType() {
      return this.readAttributeUnderscore("event_type")
    }

    hasEventType() {
      const value = this.eventType()
      return this._isPresent(value)
    }
  
    
    id() {
      return this.readAttributeUnderscore("id")
    }

    hasId() {
      const value = this.id()
      return this._isPresent(value)
    }
  
    
    payload() {
      return this.readAttributeUnderscore("payload")
    }

    hasPayload() {
      const value = this.payload()
      return this._isPresent(value)
    }
  

  

  
}


class WorkplaceLink extends BaseModel {
  static modelClassData() {
    return {"attributes":[{"name":"id"},{"name":"resource_id"},{"name":"resource_type"}],"className":"WorkerPlugins::WorkplaceLink","collectionKey":"worker_plugins/workplace_links","collectionName":"workplace_links","i18nKey":"worker_plugins/workplace_link","name":"WorkplaceLink","pluralName":"worker_plugins_workplace_links","relationships":[],"paramKey":"workplace_link","path":"/api_maker/workplace_links","primaryKey":"id"}
  }

  

  
    
    id() {
      return this.readAttributeUnderscore("id")
    }

    hasId() {
      const value = this.id()
      return this._isPresent(value)
    }
  
    
    resourceId() {
      return this.readAttributeUnderscore("resource_id")
    }

    hasResourceId() {
      const value = this.resourceId()
      return this._isPresent(value)
    }
  
    
    resourceType() {
      return this.readAttributeUnderscore("resource_type")
    }

    hasResourceType() {
      const value = this.resourceType()
      return this._isPresent(value)
    }
  

  

  
}


class AsanaIntegration extends BaseModel {
  static modelClassData() {
    return {"attributes":[{"name":"created_at"},{"name":"id"},{"name":"personal_access_token"}],"className":"AsanaIntegration","collectionKey":"asana_integrations","collectionName":"asana_integrations","i18nKey":"asana_integration","name":"AsanaIntegration","pluralName":"asana_integrations","relationships":[{"className":"Project","collectionName":"projects","name":"project","macro":"belongs_to","resource_name":"Project"}],"paramKey":"asana_integration","path":"/api_maker/asana_integrations","primaryKey":"id"}
  }

  
    
      loadProject() {
        const id = this.projectId()
        const resourceName = "Project"
        const modelClass = digg(require("api-maker/models"), resourceName)
        return this._loadBelongsToReflection({"reflectionName":"project","model":this,"modelClass":modelClass}, {"ransack":{"id_eq":id}})
      }

      project() {
        const resourceName = "Project"
        const modelClass = digg(require("api-maker/models"), resourceName)
        return this._readBelongsToReflection({"reflectionName":"project","model":this,"modelClass":modelClass})
      }
    
  

  
    
    createdAt() {
      return this.readAttributeUnderscore("created_at")
    }

    hasCreatedAt() {
      const value = this.createdAt()
      return this._isPresent(value)
    }
  
    
    id() {
      return this.readAttributeUnderscore("id")
    }

    hasId() {
      const value = this.id()
      return this._isPresent(value)
    }
  
    
    personalAccessToken() {
      return this.readAttributeUnderscore("personal_access_token")
    }

    hasPersonalAccessToken() {
      const value = this.personalAccessToken()
      return this._isPresent(value)
    }
  

  

  
}


class Account extends BaseModel {
  static modelClassData() {
    return {"attributes":[{"name":"id"},{"name":"name"}],"className":"Account","collectionKey":"accounts","collectionName":"accounts","i18nKey":"account","name":"Account","pluralName":"accounts","relationships":[{"className":"Project","collectionName":"projects","name":"projects","macro":"has_many","resource_name":"Project"}],"paramKey":"account","path":"/api_maker/accounts","primaryKey":"id"}
  }

  
    
      
        loadProjects() {
          const id = this.id()
          const resourceName = "Project"
          const modelClass = digg(require("api-maker/models"), resourceName)
          return this._loadHasManyReflection({"reflectionName":"projects","model":this,"modelClass":modelClass}, {"ransack":{"account_id_eq":id}})
        }
      

      projects() {
        const id = this.id()
        const resourceName = "Project"
        const modelClass = digg(require("api-maker/models"), resourceName)
        return new Collection({"reflectionName":"projects","model":this,"modelName":"Project","modelClass":modelClass}, {"ransack":{"account_id_eq":id}})
      }
    
  

  
    
    id() {
      return this.readAttributeUnderscore("id")
    }

    hasId() {
      const value = this.id()
      return this._isPresent(value)
    }
  
    
    name() {
      return this.readAttributeUnderscore("name")
    }

    hasName() {
      const value = this.name()
      return this._isPresent(value)
    }
  

  

  
}


class JiraIntegration extends BaseModel {
  static modelClassData() {
    return {"attributes":[{"name":"id"},{"name":"name"},{"name":"site"},{"name":"username"}],"className":"JiraIntegration","collectionKey":"jira_integrations","collectionName":"jira_integrations","i18nKey":"jira_integration","name":"JiraIntegration","pluralName":"jira_integrations","relationships":[{"className":"JiraWebhook","collectionName":"jira_webhooks","name":"jira_webhook","macro":"has_one","resource_name":"JiraWebhook"},{"className":"Project","collectionName":"projects","name":"project","macro":"belongs_to","resource_name":"Project"}],"paramKey":"jira_integration","path":"/api_maker/jira_integrations","primaryKey":"id"}
  }

  
    
      loadJiraWebhook() {
        const id = this.id()
        const resourceName = "JiraWebhook"
        const modelClass = digg(require("api-maker/models"), resourceName)
        return this._loadHasOneReflection({"reflectionName":"jira_webhook","model":this,"modelClass":modelClass}, {"ransack":{"jira_integration_id_eq":id}})
      }

      jiraWebhook() {
        const resourceName = "JiraWebhook"
        const modelClass = digg(require("api-maker/models"), resourceName)
        return this._readHasOneReflection({"reflectionName":"jira_webhook","model":this,"modelClass":modelClass})
      }
    
  
    
      loadProject() {
        const id = this.projectId()
        const resourceName = "Project"
        const modelClass = digg(require("api-maker/models"), resourceName)
        return this._loadBelongsToReflection({"reflectionName":"project","model":this,"modelClass":modelClass}, {"ransack":{"id_eq":id}})
      }

      project() {
        const resourceName = "Project"
        const modelClass = digg(require("api-maker/models"), resourceName)
        return this._readBelongsToReflection({"reflectionName":"project","model":this,"modelClass":modelClass})
      }
    
  

  
    
    id() {
      return this.readAttributeUnderscore("id")
    }

    hasId() {
      const value = this.id()
      return this._isPresent(value)
    }
  
    
    name() {
      return this.readAttributeUnderscore("name")
    }

    hasName() {
      const value = this.name()
      return this._isPresent(value)
    }
  
    
    site() {
      return this.readAttributeUnderscore("site")
    }

    hasSite() {
      const value = this.site()
      return this._isPresent(value)
    }
  
    
    username() {
      return this.readAttributeUnderscore("username")
    }

    hasUsername() {
      const value = this.username()
      return this._isPresent(value)
    }
  

  

  
    createWebhook(args, commandArgs = {}) {
      return this._callMemberCommand(
        {
          args: args,
          command: "create_webhook",
          primaryKey: this.primaryKey(),
          collectionName: this.modelClassData().collectionName,
          type: "member"
        },
        commandArgs
      )
    }
  
    fullSync(args, commandArgs = {}) {
      return this._callMemberCommand(
        {
          args: args,
          command: "full_sync",
          primaryKey: this.primaryKey(),
          collectionName: this.modelClassData().collectionName,
          type: "member"
        },
        commandArgs
      )
    }
  
}


class Project extends BaseModel {
  static modelClassData() {
    return {"attributes":[{"name":"account_id"},{"name":"id"},{"name":"name"},{"name":"updated_at"}],"className":"Project","collectionKey":"projects","collectionName":"projects","i18nKey":"project","name":"Project","pluralName":"projects","relationships":[{"className":"Account","collectionName":"accounts","name":"account","macro":"belongs_to","resource_name":"Account"}],"paramKey":"project","path":"/api_maker/projects","primaryKey":"id"}
  }

  
    
      loadAccount() {
        const id = this.accountId()
        const resourceName = "Account"
        const modelClass = digg(require("api-maker/models"), resourceName)
        return this._loadBelongsToReflection({"reflectionName":"account","model":this,"modelClass":modelClass}, {"ransack":{"id_eq":id}})
      }

      account() {
        const resourceName = "Account"
        const modelClass = digg(require("api-maker/models"), resourceName)
        return this._readBelongsToReflection({"reflectionName":"account","model":this,"modelClass":modelClass})
      }
    
  

  
    
    accountId() {
      return this.readAttributeUnderscore("account_id")
    }

    hasAccountId() {
      const value = this.accountId()
      return this._isPresent(value)
    }
  
    
    id() {
      return this.readAttributeUnderscore("id")
    }

    hasId() {
      const value = this.id()
      return this._isPresent(value)
    }
  
    
    name() {
      return this.readAttributeUnderscore("name")
    }

    hasName() {
      const value = this.name()
      return this._isPresent(value)
    }
  
    
    updatedAt() {
      return this.readAttributeUnderscore("updated_at")
    }

    hasUpdatedAt() {
      const value = this.updatedAt()
      return this._isPresent(value)
    }
  

  

  
    activeDevelopers(args, commandArgs = {}) {
      return this._callMemberCommand(
        {
          args: args,
          command: "active_developers",
          primaryKey: this.primaryKey(),
          collectionName: this.modelClassData().collectionName,
          type: "member"
        },
        commandArgs
      )
    }
  
    tasksStats(args, commandArgs = {}) {
      return this._callMemberCommand(
        {
          args: args,
          command: "tasks_stats",
          primaryKey: this.primaryKey(),
          collectionName: this.modelClassData().collectionName,
          type: "member"
        },
        commandArgs
      )
    }
  
    commitsStats(args, commandArgs = {}) {
      return this._callMemberCommand(
        {
          args: args,
          command: "commits_stats",
          primaryKey: this.primaryKey(),
          collectionName: this.modelClassData().collectionName,
          type: "member"
        },
        commandArgs
      )
    }
  
    pullRequestsStats(args, commandArgs = {}) {
      return this._callMemberCommand(
        {
          args: args,
          command: "pull_requests_stats",
          primaryKey: this.primaryKey(),
          collectionName: this.modelClassData().collectionName,
          type: "member"
        },
        commandArgs
      )
    }
  
    timesheetPerTask(args, commandArgs = {}) {
      return this._callMemberCommand(
        {
          args: args,
          command: "timesheet_per_task",
          primaryKey: this.primaryKey(),
          collectionName: this.modelClassData().collectionName,
          type: "member"
        },
        commandArgs
      )
    }
  
}


export {Account, AccountInvitation, AccountUser, AsanaIntegration, GithubWebhook, GithubWebhookDelivery, JiraIntegration, JiraWebhook, JiraWebhookDelivery, Profile, ProfileThirdPartyAccount, Project, Repository, RepositoryCommit, RepositoryCommitAuthor, RepositoryPullRequest, SiteRole, Task, ThirdPartyAccount, Timelog, User, Workplace, WorkplaceLink}

